<div class="map-title-container">
  <div class="map-title">
    {{translations.map.title | translate}} {{data?.zoneName}}
  </div>
</div>
<div class="map-content-container">
  <zui-map-container (map$)="onMapReady($event)"></zui-map-container>
</div>
<button
  class="close-button"
  mat-flat-button
  color="accent"
  (click)="closeDialog()">
  {{ translations.overview.dialog.okButton | translate }}
</button>
