import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Params } from '@angular/router';
import { DriverInfoParams } from '../driver-info/driver-info.model';
import { ParamsModel } from '@src/app/models/params-model';
import { returnHttpMockResponse } from '@src/mocks/mock-http';

@Injectable({
  providedIn: 'root'
})
export class ZoneApiService {
  private apiUrl = environment.apiBase.url;
  constructor(private http: HttpClient) {}

  getZoneActivity(paramsSent: ParamsModel): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/zone_activity/${paramsSent.company_id}`;
    const params = this.createParams(paramsSent);

    return this.http.get<any>(url, { params, observe: 'response' });
  }

  getDriverInfo(paramsSent: DriverInfoParams): Observable<HttpResponse<any>> {
    const url = `${environment.coreEntityApiBase.url}/core/v1/driverassets`;
    const params = this.createParams(paramsSent);
    return this.http.get<any>(url, { params, observe: 'response' });
  }

  getZoneResourceById(id: string): Observable<HttpResponse<any>> {
    const url = `${environment.coreEntityApiBase.url}/core/v1/zones/${id}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getZoneEntryAndExitData(
    paramsSent: ParamsModel
  ): Observable<HttpResponse<any>> {
    // const url = `${this.apiUrl}/zone_activity/${paramsSent.company_id}`;
    // const params = this.createParams(paramsSent);

    // return this.http.get<any>(url, { params, observe: 'response' });

    const mockData = {
      entryData: [11, 22, 33, 12, 4, 5, 0, 0, 0, 0, 0, 0, 10],
      exitData: [0, 2, 4, 22, 1, 10, 0, 0, 0, 0, 6, 8, 0]
    };

    return returnHttpMockResponse(mockData, 1);
  }

  getSummaryData(paramsSent: ParamsModel) {
    const url = `${this.apiUrl}/zone_summary/${paramsSent.company_id}`;
    const params = this.createParams(paramsSent);
    return this.http.get<any>(url, { params, observe: 'response' });
  }

  createParams(selection: ParamsModel | DriverInfoParams): Params {
    const queryParams: Params = {};
    Object.keys(selection).forEach((key) => {
      const value = selection[key];
      if (!value) {
        delete queryParams[key];
      } else {
        queryParams[key] = value;
      }
    });
    return queryParams;
  }
}
