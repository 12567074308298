/* eslint-disable prettier/prettier */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { BarSeriesOption, EChartsOption } from 'echarts';
import {
  checkRouteParamsAreChanged,
  getLocalTimeZone,
  minsToStr,
  strToMins} from '@src/app/shared/utilities/utilities';
import {
  distinctUntilChanged,
  catchError,
  takeUntil,
  filter,
  tap,
  map
} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@src/app/services/i18n/translations.service';
import { ZonarUiChartModule } from '@zonar-ui/chart';
import { CardComponent } from '../card/card.component';
import { ZoneApiService } from '@src/app/services/api-service/zone-api.service';
import { DeviceDetectionService } from '@src/app/services/device-detection/device-detection.service';
import { Entry } from '@src/app/shared/common.const';
import { convertToStandardDate } from '@src/app/shared/utilities/dayjs';
import dayjs from 'dayjs';

@Component({
  selector: 'app-zone-chart',
  standalone: true,
  imports: [CommonModule, CardComponent, ZonarUiChartModule],
  templateUrl: './zone-chart.component.html',
  styleUrls: []
})
export class ZoneChartComponent implements OnInit, OnDestroy {
  public loadingIndicator$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  public chartDataOption$ = new BehaviorSubject<EChartsOption>(null);
  public chartTitle: string;

  public chartData: EChartsOption = {};
  public chartHeight = '63.5rem';
  private timeAxis = [
    '00:15', '00:30', '00:45',
    '01:00', '01:15', '01:30', '01:45',
    '02:00', '02:15', '02:30', '02:45',
    '03:00', '03:15', '03:30', '03:45',
    '04:00', '04:15', '04:30', '04:45',
    '05:00', '05:15', '05:30', '05:45',
    '06:00', '06:15', '06:30', '06:45',
    '07:00', '07:15', '07:30', '07:45',
    '08:00', '08:15', '08:30', '08:45',
    '09:00', '09:15', '09:30', '09:45',
    '10:00', '10:15', '10:30', '10:45',
    '11:00', '11:15', '11:30', '11:45',
    '12:00', '12:15', '12:30', '12:45',
    '13:00', '13:15', '13:30', '13:45',
    '14:00', '14:15', '14:30', '14:45',
    '15:00', '15:15', '15:30', '15:45',
    '16:00', '16:15', '16:30', '16:45',
    '17:00', '17:15', '17:30', '17:45',
    '18:00', '18:15', '18:30', '18:45',
    '19:00', '19:15', '19:30', '19:45',
    '20:00', '20:15', '20:30', '20:45',
    '21:00', '21:15', '21:30', '21:45',
    '22:00', '22:15', '22:30', '22:45',
    '23:00', '23:15', '23:30', '23:45'
  ];
  private onDestroy$ = new Subject<void>();

  constructor(
    public translations: Translations,
    public device: DeviceDetectionService,

    private route: ActivatedRoute,
    private translateService: TranslateService,
    private zoneApiService: ZoneApiService
  ) {
    this.translateService
      .get([translations.overview.chart.title])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((translated) => {
        this.chartTitle = translated[translations.overview.chart.title];
      });
  }

  ngOnInit(): void {
    this.device.media.isActive('lt-md') ? this.chartHeight = '63.5rem' :  this.chartHeight = '15rem'
    this.route.queryParams
      .pipe(
        filter((p) => p.company_id),
        distinctUntilChanged((prev, curr) =>
          checkRouteParamsAreChanged(prev, curr)
        ),
        tap(() => {
          this.chartData = null;
          this.loadingIndicator$.next(true);
        })
      )
      .subscribe((params) => {
        this.getChartData({ ...params, timezone: getLocalTimeZone() });
      });
  }

  private getChartData(params) {
    this.zoneApiService
      .getZoneEntryAndExitData({ ...params })
      .pipe(
        map((response) => {
          this.chartData = this.getVisualizedCharData(response.body);
          this.chartDataOption$.next(this.chartData);
          this.loadingIndicator$.next(false);
        }),
        catchError((err) => {
          return err;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getVisualizedCharData(rawData: Array<any>) {
    const chartOptions: EChartsOption = {
      title: {
        text: this.chartTitle,
        textStyle: {
          fontWeight: 400,
          fontSize: 28,
          fontFamily: 'Open Sans'
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => this.formatTooltip(params)
      },
      legend: {
        selectedMode: true,
        padding: 0,
        itemHeight: 12,
        itemWidth: 16,
        textStyle: {
          color: '#49555E',
          fontSize: '0.75rem',
          fontWeight: 400
        },
        top: this.device.media.isActive('lt-md') ? 'bottom' : 8,
        right: this.device.media.isActive('lt-md') ? 'auto' : 6
      },
      grid: {
        top: 50,
        left: 8,
        right: this.device.media.isActive('lt-md') ? 16 : 20,
        bottom: 16,
        containLabel: true
      },
      series: this.makeSeries(rawData)
    };

    if (this.device.media.isActive('lt-md')) {
      chartOptions.tooltip['position'] = this.getTooltipPosition;
      chartOptions.xAxis = {
        type: 'value'
      };
      chartOptions.yAxis = {
        type: 'category',
        axisTick: {
          show: false
        },
        inverse: true,
        data: this.timeAxis
      };
    } else {
      chartOptions.yAxis = {
        type: 'value',
      };
      chartOptions.xAxis = {
        type: 'category',
        axisTick: {
          show: false
        },
        data:this.timeAxis
      };
    }
    return chartOptions;
  }

  private getTooltipPosition(point, params, dom, rect, size) {
    return params.value == 0 ? 'right' : 'top';
  }
  private makeSeries(rawDataArray: any): BarSeriesOption[] {
    // Placeholder function
    return [{
      name: Entry.ARRIVAL,
      type: 'bar',
      data: rawDataArray.entryData
    },
    {
      name: Entry.EXIT,
      type: 'bar',
      data: rawDataArray.exitData
    } as BarSeriesOption];
  }

  private formatTooltip(chartData) {
    const seriesName = chartData.seriesName;
    const dataPoint = `${minsToStr( strToMins(chartData.name) - 14)} - ${chartData.name}`;
    const totalAsset = chartData.value;
    let dataPointLabel = '';
    let totalAssetLabel = '';
    if (seriesName === Entry.ARRIVAL) {
      dataPointLabel = this.translateService.instant(
        this.translations.overview.chart.tooltip.entryLabel
      );
      totalAssetLabel = this.translateService.instant(
        this.translations.overview.chart.tooltip.totalEntryLabel
      )
    } else {
      dataPointLabel = this.translateService.instant(
        this.translations.overview.chart.tooltip.exitLabel
      );
      totalAssetLabel = this.translateService.instant(
        this.translations.overview.chart.tooltip.totalExitLabel
      )
    }
    return `<div class="line-tooltip-container">
          <div class="label"> <span class="label">${
            dataPoint
          } ${dataPointLabel}</span></div>
          <div>
            <span class="label">${totalAsset}${totalAssetLabel}</span>
          </div>
        </div>`;
  }
}
