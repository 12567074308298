import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, Routes, RouterModule } from '@angular/router';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';

import { Subject } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// zonar-ui pattern library
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';

import { Translations } from '@app/services/i18n/translations.service';

// child components
import { PageTitleComponent } from '@app/components/page-title/page-title.component';
import { ZoneActivityContainerComponent } from './../zone-activity-container/zone-activity-container.component';
import { ZonarUITableModule } from '@zonar-ui/table';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [
    FlexLayoutModule,
    CoreModule,
    CommonModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    PageTitleComponent,
    TranslateModule,
    ZoneActivityContainerComponent,
    ZonarUITableModule
  ],
  templateUrl: './overview-container.component.html',
  styleUrls: ['./overview-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewContainerComponent {
  constructor(
    public translateService: TranslateService,
    public translations: Translations
  ) {}
}
