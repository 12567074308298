import { Observable, of } from 'rxjs';

export function returnHttpMockResponse(
  data: any,
  totalCount: number
): Observable<any> {
  const mockResponse = {
    status: 200,
    statusText: 'OK',
    body: data,
    headers: { get: (_) => totalCount }
  };
  return of(mockResponse);
}
