import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule, TranslateService } from '@zonar-ui/i18n';

import { Translations } from '@app/services/i18n/translations.service';
import { DeviceDetectionService } from '@app/services/device-detection/device-detection.service';

@Component({
  selector: 'app-mobile-drawer',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './mobile-drawer.component.html',
  styleUrls: ['./mobile-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileDrawerComponent {
  @ViewChild('mobileSidenav') mobileSidenav: MatSidenav;

  constructor(
    public translateService: TranslateService,
    public translations: Translations,
    public media: DeviceDetectionService
  ) {}

  openDrawer() {
    this.mobileSidenav.open();
  }

  closeDrawer() {
    this.mobileSidenav.close();
  }
}
