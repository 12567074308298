import { DecimalPipe } from '@angular/common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Params } from '@angular/router';
import { DriverInfoSource, DriverLabel } from '../common.const';
import { isEqual, omit } from 'lodash';

export const FORMAT_DATETIME: string = 'MM/DD/YYYY HH:mm:ss z';
export const IGNORE_FILTER_KEYS = [
  'page',
  'per_page',
  'sort_by',
  'sort_order',
  'timezone'
];
export const DEFAULT_FILTER_KEYS = ['company_id', 'start_time', 'end_time'];

export const isLocalDevEnv = () => {
  // in order for unit tests (ran from localhost:9876) to pass, they need to be excluded from 'local'
  return (
    window?.location?.origin?.includes('localhost:4200') ||
    window?.location?.origin?.includes('local.dev')
  );
};

// Source: https://stackoverflow.com/a/53943624
export function repeatTimes(times: number) {
  return {
    [Symbol.iterator]: function* () {
      for (let i = 0; i < times; i++, yield) {}
    }
  };
}

export function convertDuration(
  value: string | number,
  decimalPipe: DecimalPipe
): string {
  const hrs = Number(value);
  const hh = decimalPipe.transform(Math.trunc(hrs), '2.0-0');
  const mm = ('0' + Math.trunc((hrs * 60) % 60)).slice(-2);
  const ss = ('0' + Math.trunc((hrs * 3600) % 60)).slice(-2);

  return `${hh}h ${mm}m ${ss}s`;
}

export const formatFromISODateTime = (
  dayTime: string,
  format = FORMAT_DATETIME
): string => {
  return dayTime && dayjs(dayTime).isValid()
    ? formatDateTime(dayTime, format)
    : 'N/A';
};

export const formatDateTime = (dayTime: string, format: string): string => {
  dayjs.extend(advancedFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayTime && dayjs(dayTime).isValid()
    ? dayjs(dayTime).format(format || FORMAT_DATETIME)
    : 'N/A';
};

// This function is used on distinctUntilChanged when calling issue and event view aggergation api
export function checkRouteParamsAreChanged(
  previous: Record<string, any>,
  current: Record<string, any>,
  ignoreFilterKeys: string[] = IGNORE_FILTER_KEYS //Default ignoring keys - page, per_page, sort param changes
): boolean {
  if (previous && current) {
    return isEqual(
      omit(current, ignoreFilterKeys),
      omit(previous, ignoreFilterKeys)
    );
  }
  return false;
}

export function isPaginationParamsChanged(previous: Params, current: Params) {
  return (
    previous?.sort_by != current?.sort_by ||
    previous?.sort_order != current?.sort_order ||
    previous?.page != current?.page ||
    (previous?.per_page && previous?.per_page != current.per_page)
  );
}

export function removeInvalidSortParams(
  params: Params,
  sortableColumns: string[]
): Params {
  const newParams = { ...params };
  if (newParams.sort_by && !sortableColumns.includes(newParams.sort_by)) {
    delete newParams.sort_by;
    delete newParams.sort_order;
  }
  return newParams;
}

/**
 * Compare 2 values, only support primitive type, string, array
 * @param a
 * @param b
 */
export function equals(a, b) {
  if (typeof a !== typeof b) {
    return false;
  } else if (Array.isArray(a) && Array.isArray(b)) {
    return isArrayEquals(a, b);
  } else {
    return a == b;
  }
}

export function isArrayEquals(arr1: Array<any>, arr2: Array<any>) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export const isStartUTCDate = (
  dateTime: string | Date | dayjs.Dayjs
): boolean => {
  const date = dayjs.utc(dateTime);
  return (
    date.get('hour') === 0 &&
    date.get('minute') === 0 &&
    date.get('second') === 0
  );
};

export const isEndUTCDate = (
  dateTime: string | Date | dayjs.Dayjs
): boolean => {
  const date = dayjs.utc(dateTime);
  return (
    date.get('hour') === 23 &&
    date.get('minute') === 59 &&
    date.get('second') === 59
  );
};

export function getDriverPrefixLabel(translatePath: any, key: string) {
  let labelMap = new Map<string, string>([
    [DriverInfoSource.PRE_TRIP, DriverLabel.PRE_TRIP],
    [DriverInfoSource.EVIR_MOBILE, DriverLabel.EVIR_MOBILE],
    [DriverInfoSource.EXTERNAL, DriverLabel.EXTERNAL],
    [DriverInfoSource.OPERATOR_ID, DriverLabel.OPERATOR_ID],
    [DriverInfoSource.TABLET, DriverLabel.TABLET],
    [DriverInfoSource.NOT_AVAIL, DriverLabel.NOT_AVAIL]
  ]);

  return key
    ? translatePath[labelMap.get(key)]
    : translatePath[labelMap.get(DriverInfoSource.NOT_AVAIL)];
}

export function getLocalTimeZone(): string {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs.tz.guess();
}

export function getDefaultFilters(
  params: Record<string, any>,
  defaultFilterKeys: string[] = DEFAULT_FILTER_KEYS
): Record<string, any> {
  return defaultFilterKeys.reduce(
    (previousVal: Record<string, any>, k: string) =>
      Object.assign(previousVal, { [k]: params[k] }),
    {}
  );
}

export function strToMins(t) {
  var s = t.split(':');
  return Number(s[0]) * 60 + Number(s[1]);
}

export function minsToStr(t) {
  return (
    ('00' + Math.trunc(t / 60)).slice(-2) + ':' + ('00' + (t % 60)).slice(-2)
  );
}
