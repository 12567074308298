import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';

import { TranslateService, TranslateModule } from '@zonar-ui/i18n';
import { Translations } from '@app/services/i18n/translations.service';
import { DeviceDetectionService } from '@src/app/services/device-detection/device-detection.service';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule, FlexModule, MatCardModule, TranslateModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
  @Input() title: string;
  @Input() dataRange: string;
  @Input() class: string;

  constructor(
    public translateService: TranslateService,
    public translations: Translations,
    public device: DeviceDetectionService
  ) {}
}
