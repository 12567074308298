import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/services/i18n/translations.service';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

export interface DateRangeObject {
  title: string;
  value: string;
  numValue: number;
  dateRange?: any;
}

@Injectable({
  providedIn: 'root'
})
export class DateRangePresetsDefinitions implements OnDestroy {
  private _translated;
  private _onDestroy$ = new Subject<void>();
  private _dateRangePresets: DateRangeObject[];
  constructor(
    public translateService: TranslateService,
    public translations: Translations
  ) {
    this.translateService
      .get([
        this.translations.overview.filters.dateRanges.today,
        this.translations.overview.filters.dateRanges.previous7Days,
        this.translations.overview.filters.dateRanges.previous14Days,
        this.translations.overview.filters.dateRanges.previous30Days,
        this.translations.overview.filters.dateRanges.previous365Days,
        this.translations.overview.filters.dateRanges.custom
      ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((translated) => (this._translated = translated));

    this._dateRangePresets = [
      {
        title:
          this._translated[this.translations.overview.filters.dateRanges.today],
        value: 'today',
        numValue: 0
      },
      {
        title:
          this._translated[
            this.translations.overview.filters.dateRanges.previous7Days
          ],
        value: 'previous-7-days',
        numValue: 7
      },
      {
        title:
          this._translated[
            this.translations.overview.filters.dateRanges.previous14Days
          ],
        value: 'previous-14-days',
        numValue: 14
      },
      {
        title:
          this._translated[
            this.translations.overview.filters.dateRanges.previous30Days
          ],
        value: 'previous-30-days',
        numValue: 30
      },
      {
        title:
          this._translated[
            this.translations.overview.filters.dateRanges.previous365Days
          ],
        value: 'previous-365-days',
        numValue: 365
      },
      {
        title:
          this._translated[
            this.translations.overview.filters.dateRanges.custom
          ],
        value: 'custom',
        numValue: undefined
      }
    ];
  }

  public getDateRangePresets() {
    return this._dateRangePresets;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
