<ul>
  <ng-container *ngFor="let item of data">
    <li class="summary-filter-item">
      <span class="mobile-summary-title">{{ item.title | translate }}</span>
      <span
        class="mobile-summary-value"
        *ngIf="(loadStatus === resourceLoadState.LOAD_SUCCESSFUL) else loading"
        >{{ item.value }}</span
      >
    </li>
  </ng-container>
</ul>
<ng-template #loading>
  <ngx-skeleton-loader
    count="1"
    [theme]="{'width': '85%', 'margin-left': '2.5%' , 'border-radius':'3px'}">
  </ngx-skeleton-loader>
</ng-template>
