import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@src/app/services/i18n/translations.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { DriverInfoService } from '@src/app/services/driver-info/driver-info.service';
import {
  formatFromISODateTime,
  getDriverPrefixLabel
} from '@src/app/shared/utilities/utilities';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Params, RouterModule } from '@angular/router';
import { QueryParamsPipe } from '@src/app/pipes/query-params.pipe';

@Component({
  selector: 'app-asset-driver-info',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatTooltipModule,
    QueryParamsPipe
  ],
  templateUrl: './asset-driver-info.component.html',
  styleUrls: ['./asset-driver-info.component.scss']
})
export class AssetDriverInfoComponent implements OnDestroy {
  @Input() driver: any;
  @Output() selectedDriverEvent = new EventEmitter();

  public tooltipContext$ = new BehaviorSubject<string>('');

  private _translated;
  private onDestroy$ = new Subject<void>();

  constructor(
    private driverInfoService: DriverInfoService,
    public route: ActivatedRoute,
    public translateService: TranslateService,
    public translations: Translations
  ) {
    this.translateService
      .get([
        this.translations.overview.zoneActivityTable.loadingTooltip,
        this.translations.overview.zoneActivityTable.tooltipPrefix.evirMobile,
        this.translations.overview.zoneActivityTable.tooltipPrefix.external,
        this.translations.overview.zoneActivityTable.tooltipPrefix.operator,
        this.translations.overview.zoneActivityTable.tooltipPrefix.preTrip,
        this.translations.overview.zoneActivityTable.tooltipPrefix.tablet,
        this.translations.overview.zoneActivityTable.tooltipPrefix.notAvail
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((_translated) => {
        this._translated = _translated;
        this.tooltipContext$.next(
          this._translated[
            this.translations.overview.zoneActivityTable.loadingTooltip
          ]
        );
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getTooltipContext = (inputParams: any) => {
    let driverParams = {
      status: 'ANY',
      driverProfileId: inputParams.driverProfile.driverProfileId,
      assetId: inputParams.assetId,
      startTimestamp: inputParams.startTime,
      timeWindow: true,
      sort: 'startTimestamp:asc'
    };

    this.driverInfoService.getDriverInfo(driverParams).subscribe((res) => {
      let prefixText = getDriverPrefixLabel(
        this.translations.overview.zoneActivityTable.tooltipPrefix,
        res?.source
      );

      let timeStamp = res?.startTimestamp;
      if (timeStamp && !timeStamp.endsWith('Z')) {
        timeStamp = timeStamp + 'Z'; // If date time input has no timezone, default
      }
      this.tooltipContext$.next(
        `${this._translated[prefixText]} ${
          timeStamp ? formatFromISODateTime(timeStamp) : ''
        }`
      );
    });
  };

  getQueryParams(): Params {
    return {
      ...this.route.snapshot.queryParams,
      driver_id: this.driver.driverProfile.driverProfileId,
      page: 1,
      per_papge: 10
    };
  }

  onDriverNameClick() {
    this.selectedDriverEvent.emit(this.driver.driverProfile);
  }
}
