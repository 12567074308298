import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CardComponent } from '@app/components/card/card.component';
import { DialogComponent } from '@app/components/dialog/dialog.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { FilterSummaryComponent } from './components/filter-summary/filter-summary.component';
import { DeviceDetectionService } from '@services/device-detection/device-detection.service';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { ResourceLoadState } from '@models/resource-load.state';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@services/i18n/translations.service';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';
import { SummaryModel } from '@src/app/models/summary-model';
import { MobileFilterSummaryComponent } from './components/mobile-filter-summary/mobile-filter-summary.component';
import {
  convertToStandardDate,
  formatFromISODateTime,
  getLocalTimeZone
} from '@src/app/shared/utilities/dayjs';
import {
  checkRouteParamsAreChanged,
  convertDuration
} from '@src/app/shared/utilities/utilities';
import { ZoneApiService } from '@src/app/services/api-service/zone-api.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    CardComponent,
    CommonModule,
    DialogComponent,
    MatGridListModule,
    TranslateModule,
    FilterSummaryComponent,
    MobileFilterSummaryComponent,
    NgxSkeletonLoaderModule
  ],
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  public hasFilterData: boolean = false;
  public loadStatus$ = new BehaviorSubject<ResourceLoadState>(
    ResourceLoadState.INITIAL
  );
  public headline: string;
  public subtext: string;
  public dataRange: string;
  public supportSiteLink: string =
    'https://support.zonarsystems.net/hc/en-us/articles/25793422115853';

  private _onDestroy$ = new Subject<void>();

  constructor(
    public translateService: TranslateService,
    public translations: Translations,
    public device: DeviceDetectionService,
    private zoneApiService: ZoneApiService,
    private route: ActivatedRoute,
    private selectedCompanyService: SelectedCompanyService,
    private decimalPipe: DecimalPipe
  ) {
    this.filterData = this.formatValues({
      totalVisits: 0,
      avgDuration: '',
      avgArrivalTime: '',
      avgExitTime: '',
      avgEngineHours: ''
    });
  }
  public filterData = [];

  ngOnInit(): void {
    combineLatest([
      this.selectedCompanyService
        .getCompanyFromSideNav()
        .pipe(
          distinctUntilChanged((prev, current) => prev.value != current.value)
        ),
      this.route.queryParams.pipe(
        filter((params) => params.company_id),
        distinctUntilChanged((prev, current) =>
          checkRouteParamsAreChanged(prev, current)
        )
      )
    ])
      .pipe(
        takeUntil(this._onDestroy$),
        switchMap((results) => {
          let params = results[1];
          this.loadStatus$.next(ResourceLoadState.LOADING);
          const { start_time, end_time } = params;
          this.dataRange =
            formatFromISODateTime(start_time, 'MM/DD/YYYY') +
            ' ' +
            ' - ' +
            formatFromISODateTime(end_time, 'MM/DD/YYYY') +
            ' ';

          params = { ...params, timezone: getLocalTimeZone() };
          this.hasFilterData = true;
          return this.zoneApiService.getSummaryData({
            ...params,
            start_time,
            end_time
          });
        }),
        map((results: any) => {
          this.filterData = this.formatValues({ ...results.body });
          return ResourceLoadState.LOAD_SUCCESSFUL;
        }),
        catchError((err) => {
          return ResourceLoadState.LOAD_FAILURE;
        })
      )
      .subscribe((loadState: ResourceLoadState) => {
        this.loadStatus$.next(loadState);
      });
  }

  formatValues(input: SummaryModel) {
    return [
      {
        title: this.translations.overview.summary.totalTrip,
        value: input.totalVisits ?? 'N/A'
      },
      {
        title: this.translations.overview.summary.averageArrivalTime,
        value: input.avgArrivalTime
          ? `${formatFromISODateTime(
              convertToStandardDate(input.avgArrivalTime),
              'HH:mm z'
            )}`
          : 'N/A'
      },
      {
        title: this.translations.overview.summary.averageExitTime,
        value: input.avgExitTime
          ? `${formatFromISODateTime(
              convertToStandardDate(input.avgExitTime),
              'HH:mm z'
            )}`
          : 'N/A'
      },
      {
        title: this.translations.overview.summary.averageDuration,
        value: `${convertDuration(
          Number(input.avgDuration) / 3600,
          this.decimalPipe
        )}`
      },
      {
        title: this.translations.overview.summary.engineHour,
        value: `${convertDuration(
          Number(input.avgEngineHours) / 3600,
          this.decimalPipe
        )}`
      }
    ];
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  public get title(): string {
    return this.translations.overview.summary.title;
  }
}
