<div>
  <ng-container
    *ngIf="device.mediaObserver.isActive('lt-md'); else desktopView">
    <app-mobile-drawer>
      <zui-filters-bar
        [filterList]="filterList"
        [companyId]="companyId$ | async"
        (OnFilterChanged)="receiveFilterSelection($event)">
      </zui-filters-bar>
    </app-mobile-drawer>

    <div
      class="data-viz-wrapper"
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="1rem">
      <app-summary fxFlex="100" class="data-viz-child"></app-summary>
    </div>
  </ng-container>

  <ng-template #desktopView>
    <app-card class="filter-bar-wrapper">
      <zui-filters-bar
        [filterList]="filterList"
        [companyId]="companyId$ | async"
        (OnFilterChanged)="receiveFilterSelection($event)">
      </zui-filters-bar>
    </app-card>
    <div
      *ngIf="isShowSummaryWidget"
      class="data-viz-wrapper"
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="1rem">
      <app-summary fxFlex="100" class="data-viz-child"></app-summary>
    </div>
  </ng-template>

  <app-zone-chart *ngIf="isShowVisualChart"></app-zone-chart>
  <app-zone-activity-table
    [sendPaginationSelection]="receivePaginationSelection.bind(this)"
    [showZoneFilter]="isShowZoneFilter"
    (receiveAssetSelection)="receiveAssetSelection($event)"
    (receiveZoneSelection)="receiveZoneSelection($event)"
    (receiveDriverSelection)="receiveDriverSelection($event)"></app-zone-activity-table>
</div>
