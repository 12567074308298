import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

import { ZonarUiIconModule } from '@zonar-ui/icon';
import { TranslateService, TranslateModule } from '@zonar-ui/i18n';

import { Translations } from '@app/services/i18n/translations.service';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ZonarUiIconModule,
    MatButtonModule,
    FlexModule,
    TranslateModule
  ],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @Input() class: string;
  constructor(
    public translateService: TranslateService,
    public translations: Translations
  ) {}
}
