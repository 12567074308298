<app-card
  class="filter-summary-container"
  [title]="title"
  [dataRange]="dataRange">
  <app-dialog class="summary-dialog" action>
    <h2 class="title">
      {{ translateService.get(translations.overview.dialog.summary.title) |
      async | translate }}
    </h2>
    <p>
      {{ translateService.get(translations.overview.dialog.summary.subTitle) |
      async | translate }}
    </p>

    <h4 class="total-visit-title">
      {{ translateService.get(translations.overview.dialog.summary.totalTrip) |
      async | translate }}
    </h4>
    <p>
      {{
      translateService.get(translations.overview.dialog.summary.totalTripContext)
      | async | translate }}
    </p>

    <h4 class="avg-duration-title">
      {{
      translateService.get(translations.overview.dialog.summary.averageDuration)
      | async | translate }}
    </h4>
    <p>
      {{
      translateService.get(translations.overview.dialog.summary.averageDurationContext)
      | async | translate }}
    </p>

    <h4 class="avg-arrival-title">
      {{
      translateService.get(translations.overview.dialog.summary.averageArrivalTime)
      | async | translate }}
    </h4>
    <p>
      {{
      translateService.get(translations.overview.dialog.summary.averageArrivalTimeContext)
      | async | translate }}
    </p>

    <h4 class="avg-exit-title">
      {{
      translateService.get(translations.overview.dialog.summary.averageExitTime)
      | async | translate }}
    </h4>
    <p>
      {{
      translateService.get(translations.overview.dialog.summary.averageExitTimeContext)
      | async | translate }}
    </p>

    <h4 class="engine-hour-title">
      {{ translateService.get(translations.overview.dialog.summary.engineHour) |
      async | translate }}
    </h4>
    <p>
      {{
      translateService.get(translations.overview.dialog.summary.engineHourContext)
      | async | translate }}
    </p>
  </app-dialog>
  <ng-container>
    <div *ngIf="device.mediaObserver.isActive('gt-sm')">
      <app-filter-summary
        *ngIf="hasFilterData"
        [loadStatus]="loadStatus$ | async"
        [data]="filterData"></app-filter-summary>
    </div>
    <div *ngIf="device.mediaObserver.isActive('lt-md')" class="mobile-summary">
      <app-mobile-filter-summary
        class="mobile-summary-filter"
        *ngIf="hasFilterData"
        [loadStatus]="loadStatus$ | async"
        [data]="filterData"></app-mobile-filter-summary>
    </div>
  </ng-container>
</app-card>
