import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ZonarUiMapModule, MapFacadeService } from '@zonar-ui/zonar-ui-map';

import { ZonarUiIconModule } from '@zonar-ui/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Translations } from '@src/app/services/i18n/translations.service';
import { MatButtonModule } from '@angular/material/button';
import L, { icon, latLng, point } from 'leaflet';

@Component({
  selector: 'app-map-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    ZonarUiIconModule,
    ZonarUiMapModule
  ],
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MapDialogComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    private mapFacade: MapFacadeService
  ) {
    L.Icon.Default.imagePath = 'assets/images/';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onMapReady(_: any) {
    const labelOptions = {
      style: {
        color: '#2B53D6',
        weight: 2,
        opacity: 1,
        fillOpacity: 0
      }
    };
    const geometry = {
      ...this.data?.geometry,
      features: [
        ...this.data?.geometry.features.filter(
          (geo) => geo.properties.name === 'GEOMETRY'
        )
      ]
    };
    const mainPath = this.mapFacade.getAddGeoJsonObject(geometry, labelOptions);
    const centroid = this.data?.geometry?.features[0]?.geometry.coordinates;
    const _latLng = latLng(centroid[1], centroid[0]);
    const markerText = new L.DivIcon({
      className: 'div-icon-text',
      html: '<div>' + this.data.zoneName + '</div>',
      iconSize: point(160, 24)
    });
    const marker = new L.Marker(_latLng, { icon: markerText });
    setTimeout(() => {
      this.mapFacade.getAddLayerToMap(mainPath);
      this.mapFacade.getAddLayerToMap(marker);
      this.mapFacade.setView(_latLng, 12);
    }, 100);
  }
}
