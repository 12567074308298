<div class="mobile-drawer-open-button-container" fxLayoutAlign="end none">
  <button
    mat-icon-button
    (click)="openDrawer()"
    class="drawer-open-button"
    fxLayoutAlign="end center"
    fxLayoutGap="0.5rem">
    <span class="drawer-open-button-label">Filter</span>
    <mat-icon class="drawer-open-button-icon">filter_list</mat-icon>
  </button>
</div>

<mat-sidenav
  #mobileSidenav
  mode="over"
  hasBackdrop="true"
  position="end"
  opened="false"
  class="mobile-drawer-panel">
  <div
    class="drawer-header"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <h3 class="drawer-header-title">
      {{ translateService.get(translations.drawer.title) | async | translate}}
    </h3>

    <button mat-icon-button class="drawer-close-button" (click)="closeDrawer()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="drawer-content-container">
    <ng-content></ng-content>
  </div>
</mat-sidenav>
