<div class="dialog-container" [ngClass]="class">
  <zui-icon
    class="info-icon"
    icon="info_outline"
    (click)="iconModal.showModal()">
  </zui-icon>

  <dialog class="dialog-content-container" #iconModal>
    <ng-content></ng-content>
    <button
      class="close-button"
      mat-flat-button
      color="accent"
      (click)="iconModal.close()">
      {{ translateService.get( translations.overview.dialog.okButton ) | async |
      translate }}
    </button>
  </dialog>
</div>
