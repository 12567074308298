<div class="overview-container">
  <app-page-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h1>
        {{ translateService.get(translations?.overview.pageTitle) | async |
        translate }}
      </h1>
    </div>
  </app-page-title>
  <app-zone-activity-container></app-zone-activity-container>
</div>
