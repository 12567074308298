import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MapDialogComponent } from '@src/app/components/map-dialog/map-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  showMapDialog(data: any) {
    const smallScreen = 950;
    const screenWidth = window.innerWidth;
    const dialogWidth = screenWidth > smallScreen ? '60.438rem' : '100%';
    const dialogHeight = screenWidth > smallScreen ? '59.25rem' : '100%';

    return this.dialog
      .open(MapDialogComponent, {
        data,
        width: dialogWidth,
        height: dialogHeight,
        disableClose: true,
        closeOnNavigation: true
      })
      .afterClosed();
  }
}
