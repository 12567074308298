import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Params, ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { ZonarUIBaseCsvExportService } from '@zonar-ui/table';
import { HttpService } from '@zonar-ui/core';
import {
  formatTimeStamp,
  getLocalTimeZone
} from '@src/app/shared/utilities/dayjs';

@Injectable({
  providedIn: 'root'
})
export class CsvExportService extends ZonarUIBaseCsvExportService<
  HttpResponse<Blob>
> {
  public queryParams: any;
  private apiUrl = environment.apiBase.url;
  private urlBase = `${this.apiUrl}/zone_activity/`;
  public options: any;
  public urlParams: any;
  public companyId: any;

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute
  ) {
    super();
    this.route.queryParams.subscribe((params) => {
      this.queryParams = { ...params };
      this.fileName = this.getFileName();
      this.serviceCallObservable = this.callUrlForCompanyId(params.viewBy, {
        ...params,
        timezone: getLocalTimeZone(),
        csv_filename: `${this.fileName}.csv`
      });
    });
  }

  callUrlForCompanyId(
    view: string,
    params: any
  ): Observable<HttpResponse<Blob>> {
    return this.callHttpService(`${this.urlBase}${params.company_id}`, params);
  }

  callHttpService(url: string, params: any): Observable<HttpResponse<Blob>> {
    const callParams = this.createParams(params);
    const httpParams: HttpParams = new HttpParams({ fromObject: callParams });
    return this.httpService.getBlob(url, httpParams);
  }

  createParams(selection: any): Params {
    const queryParams: Params = {};
    Object.keys(selection).forEach((key) => {
      const value = selection[key];
      if (value !== null && value !== undefined) {
        queryParams[key] = value;
      }
    });
    // Removing unneeded parameters from call
    delete queryParams['companyId'];
    delete queryParams['viewBy'];
    return queryParams;
  }

  getFileName(): string {
    // Sometimes viewBy field is array, not sure why it's happening investigate separately.
    const view = Array.isArray(this.queryParams.viewBy)
      ? this.queryParams.viewBy[0]
      : this.queryParams.viewBy;
    const fileName = `Zone-visit-activity-Report-${formatTimeStamp(
      new Date(),
      'YYYY-MM-dd_HH_mm_ss'
    )}`;
    return fileName;
  }
}
