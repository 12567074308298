import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { ZonarUITableComponent, ZonarUITableModule } from '@zonar-ui/table';
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';
import { MatButtonModule } from '@angular/material/button';
import { Translations } from '@app/services/i18n/translations.service';
import { ZoneActivityTableDataSource } from './services/zone-activity-table.datasource';
import { DeviceDetectionService } from '@app/services/device-detection/device-detection.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { CardComponent } from '@app/components/card/card.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil
} from 'rxjs/operators';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';
import { CsvExportService } from '@src/app/services/csv-export-service/csv-export.service';
import { ColumnDefinitions } from './services/column-definitions';
import { Asset, ZuiAssetNameComponent } from '@zonar-ui/asset-properties';
import {
  checkRouteParamsAreChanged,
  removeInvalidSortParams
} from '@src/app/shared/utilities/utilities';
import { QueryParamsPipe } from '@src/app/pipes/query-params.pipe';
import { MatIconModule } from '@angular/material/icon';
import { AssetDriverInfoComponent } from '../asset-driver-info/asset-driver-info.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogService } from '@src/app/services/dialog/dialog.service';
import { ZoneApiService } from '@src/app/services/api-service/zone-api.service';

@Component({
  selector: 'app-zone-activity-table',
  standalone: true,
  providers: [ZoneActivityTableDataSource, ColumnDefinitions],
  templateUrl: './zone-activity-table.component.html',
  styleUrls: ['./zone-activity-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CardComponent,
    CommonModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    ZonarUITableModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    QueryParamsPipe,
    ZuiAssetNameComponent,
    AssetDriverInfoComponent
  ]
})
export class ZoneActivityTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(ZonarUITableComponent) zoneActivityTable: ZonarUITableComponent;
  @ViewChild('zoneCells') zoneCellsTemplate: TemplateRef<unknown>;
  @ViewChild('assetName') assetNameTemplate: TemplateRef<unknown>;
  @ViewChild('driverName') driverNameTemplate: TemplateRef<unknown>;
  @Input() sendPaginationSelection: (params: Params) => void;
  @Input() showZoneFilter: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  @Output() receiveAssetSelection = new EventEmitter();
  @Output() receiveZoneSelection = new EventEmitter();
  @Output() receiveDriverSelection = new EventEmitter();

  public title: string;
  public eventCountCellPortals: Record<string, TemplatePortal<any>> = {};
  public columns$ = new BehaviorSubject(null);
  public uniqueRowIdentifier = 'id';
  public selectedDataRows: any;
  public csvExportOptions = {
    btnLabel: '',
    exportThreshold: 150000,
    csvExportService: this.csvExportService
  };
  public companyId: string;
  public defaultSortBy: string = 'zoneName';
  public defaultSortOrder: string = 'asc';

  private columns: ZonarUITableModule[];
  private onDestroy$ = new Subject<void>();
  private _translated;

  constructor(
    public device: DeviceDetectionService,
    public route: ActivatedRoute,
    public router: Router,
    public translateService: TranslateService,
    public translations: Translations,
    public viewContainerRef: ViewContainerRef,
    public dataSource: ZoneActivityTableDataSource,
    private selectedCompanyService: SelectedCompanyService,
    private columnDefinitions: ColumnDefinitions,
    private csvExportService: CsvExportService,
    private dialogService: DialogService,
    private zoneApiService: ZoneApiService
  ) {
    this.translateService
      .get([
        this.translations.overview.zoneActivityTable.title,
        this.translations.csvExportButton.title
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((_translated) => (this._translated = _translated));
    if (
      this.route.snapshot.queryParams.sort_by &&
      this.columnDefinitions.sortableColumns.includes(
        this.route.snapshot.queryParams.sort_by
      )
    ) {
      this.defaultSortBy = this.route.snapshot.queryParams.sort_by;
    }
    if (this.route.snapshot.queryParams.sort_order) {
      this.defaultSortOrder = this.route.snapshot.queryParams.sort_order;
    }

    combineLatest([
      this.selectedCompanyService
        .getCompanyFromSideNav()
        .pipe(
          distinctUntilChanged((prev, current) => prev.value != current.value)
        ),
      this.route.queryParams.pipe(
        filter((params) => params.company_id),
        distinctUntilChanged((prev, current) =>
          checkRouteParamsAreChanged(prev, current, [])
        )
      )
    ])
      .pipe(takeUntil(this.onDestroy$), debounceTime(200))
      .subscribe(([_, params]) => {
        this.companyId = params.company_id;
        const copyParams = { ...params };
        if (Number(copyParams.page || 1) === 1) {
          this.dataSource.resetPage();
        }
        this.dataSource.fetchData(
          removeInvalidSortParams(
            params,
            this.columnDefinitions.sortableColumns
          )
        );
      });
  }

  ngOnInit(): void {
    this.csvExportOptions.btnLabel =
      this._translated[this.translations.csvExportButton.title];
    this.title =
      this._translated[this.translations.overview.zoneActivityTable.title];
    this.columns = this.columnDefinitions.getColumnDefinitions();
    this.columns$.next(this.columns);
    this.dataSource.paginationParams$.subscribe((params) => {
      this.sendPaginationSelection(params);
    });
  }

  ngOnDestroy(): void {
    // Manually call the equivalent of disconnect here.
    this.dataSource.onTableDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // Reference from: https://stackoverflow.com/questions/71978152
  ngAfterViewInit(): void {
    setTimeout(() => {
      // The property name should match with columnDef in column-definition.ts
      this.eventCountCellPortals = {
        assetName: new TemplatePortal(
          this.assetNameTemplate,
          this.viewContainerRef
        ),
        driverName: new TemplatePortal(
          this.driverNameTemplate,
          this.viewContainerRef
        ),
        zoneName: new TemplatePortal(
          this.zoneCellsTemplate,
          this.viewContainerRef
        )
      };
    });
  }

  handleAssetClick(event: Asset) {
    this.receiveAssetSelection.emit(event.id);
  }

  handleZoneClick(zoneId: string) {
    this.receiveZoneSelection.emit(zoneId);
  }

  onDriverSelected(driverProfile: any) {
    this.receiveDriverSelection.emit(driverProfile.driverProfileId);
  }

  handleZoneInfoClick(zoneData: any) {
    this.zoneApiService
      .getZoneResourceById(zoneData.zoneId)
      .subscribe((res) => {
        if (res.status === 200) {
          this.dialogService.showMapDialog({
            geometry: res.body.geometry,
            zoneName: zoneData.zoneName
          });
        }
      });
  }
}
