import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@zonar-ui/i18n';
import {
  ZuiDateFilterModel,
  SearchableDropdownModel,
  FILTER_TYPE,
  ZuiAssetFilterModel,
  DropdownOptionsObject,
  ZuiAssetPropertyModel,
  ZuiDriverFilterModel,
  ZuiMultiZoneFilterModel
} from '@zonar-ui/filter';
import { Translations } from '@app/services/i18n/translations.service';
import { DateRangePresetsDefinitions } from '@src/app/services/filters-bar/date-range-definitions';
import { Observable, Subject, of } from 'rxjs';
import dayjs from 'dayjs';

type FILTER_MODAL =
  | ZuiDateFilterModel
  | SearchableDropdownModel
  | ZuiAssetFilterModel
  | ZuiAssetPropertyModel
  | ZuiDriverFilterModel
  | ZuiMultiZoneFilterModel;

@Injectable({
  providedIn: 'root'
})
export class FiltersBarService {
  private _translated;
  private _onDestroy$ = new Subject<void>();
  private _dateRangePreSets = [];
  constructor(
    private dateRangePreSetsDefs: DateRangePresetsDefinitions,
    private translateService: TranslateService,
    private translations: Translations
  ) {
    this._dateRangePreSets = this.dateRangePreSetsDefs.getDateRangePresets();
    this.translateService
      .get([
        this.translations.overview.filters.filterNames.asset,
        this.translations.overview.filters.filterNames.driver,
        this.translations.overview.filters.filterNames.zone,
        this.translations.overview.filters.filterNames.assetStatus
      ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((translated) => (this._translated = translated));
  }

  getFilterList(isShowZoneFilter: boolean = false): FILTER_MODAL[] {
    const commonDropdownProps = {
      isMultiple: true,
      blueCheckmarks: true,
      defaultValue: null,
      inputParams: [],
      enableAllOptions: true
    };
    const defaultDate = this.getDefaultDate();
    let filters: FILTER_MODAL[] = [
      {
        type: FILTER_TYPE.DATE_RANGE,
        options: {
          datePreset: this._dateRangePreSets,
          selectedDateFilter: defaultDate,
          defaultVal: defaultDate,
          paramName: ['start_time', 'end_time']
        }
      } as ZuiDateFilterModel,
      {
        type: FILTER_TYPE.ASSET,
        options: {
          label:
            this._translated[
              this.translations.overview.filters.filterNames.asset
            ],
          selectedAssetsInput: null,
          companyId: '',
          zonarOwnerId: '',
          paramName: ['division_id', 'asset_id'],
          showApplyButton: true,
          compactMode: true,
          assetTypes: ['legacyAttributes:Standard', 'legacyAttributes:Unknown']
        }
      } as ZuiAssetFilterModel,
      {
        type: FILTER_TYPE.MULTI_ZONE_FILTER,
        options: {
          label:
            this._translated[
              this.translations.overview.filters.filterNames.zone
            ],
          companyId: '',
          zonarOwnerId: '',
          selectedZonesInput: null,
          paramName: ['category_id', 'zone_id'],
          showApplyButton: true,
          compactMode: true
        }
      } as ZuiMultiZoneFilterModel,
      {
        type: FILTER_TYPE.ASSET_PROPERTIES_FILTER,
        options: {},
        companyId: '' // Company passed as input param to the FilterBar component.
      } as ZuiAssetPropertyModel,
      {
        type: FILTER_TYPE.DRIVER_FILTER,
        options: {
          label:
            this._translated[
              this.translations.overview.filters.filterNames.driver
            ],
          selectedDriversInput: null,
          companyId: '',
          paramName: ['driver_division_id', 'driver_id'],
          showApplyButton: true,
          compactMode: true,
          status: 'ACTIVE'
        }
      } as ZuiDriverFilterModel,
      {
        type: FILTER_TYPE.SEARCHABLE_DROPDOWN,
        options: {
          label:
            this._translated[
              this.translations.overview.filters.filterNames.assetStatus
            ],
          data: this.getAssetStatusFilterOptions(),
          fgControlName: 'assetStatus',
          paramName: 'asset_status',
          valueType: 'number',
          ...commonDropdownProps
        }
      } as SearchableDropdownModel
    ];
    if (!isShowZoneFilter) {
      filters = filters.filter(
        (item) => item.type !== FILTER_TYPE.MULTI_ZONE_FILTER
      );
    }

    return filters;
  }

  private getAssetStatusFilterOptions(): Observable<DropdownOptionsObject[]> {
    return of([
      {
        title: 'Active',
        value: 'ACTIVE'
      },
      {
        title: 'Inactive',
        value: 'INACTIVE'
      }
    ]);
  }

  private getDefaultDate(): any {
    return {
      title: 'Today',
      value: 'today',
      numValue: 0,
      isDefault: true,
      dateRange: {
        start: dayjs().startOf('day').toDate(),
        end: dayjs().toDate()
      }
    };
  }
}
