import { ActivatedRoute, Router } from '@angular/router';
import {
  ASSET_FILTER_KEY_NAME,
  ASSET_PROPERTIES_FILTER_KEY_NAME,
  AssetFilterSelectionModel,
  DRIVER_FILTER_KEY_NAME,
  DriverFilterSelectionModel,
  MULTI_ZONE_FILTER_KEY_NAME,
  ZoneFilterSelectionModel,
  ZuiFiltersBarComponent,
  ZuiMultiZoneFilterModel
} from '@zonar-ui/filter';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { SelectedCompanyService } from '../services/selected-company/selected-company.service';
import { getDefaultDateRange } from '../shared/utilities/dayjs';

/**
 * Common container component class logic
 */
export class BaseContainerComponent {
  filterComponent: ZuiFiltersBarComponent;

  public queryParams: any = {};
  public companyId$: Observable<string> =
    this.companyService.getUserSelectedCompanyId();
  public filterList = [];

  private onDestroy$ = new Subject<void>();
  private currentSelectedCompanyId: string;

  constructor(
    private r: Router,
    private activatedRoute: ActivatedRoute,
    private companyService: SelectedCompanyService
  ) {}

  initComponent(): void {
    let params = this.activatedRoute?.snapshot?.queryParams;
    this.queryParams = { ...getDefaultDateRange(0), ...params };
    this.companyService
      .getCompanyId()
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        tap((id) => {
          // On First load current selected companyId is null.
          if (!this.currentSelectedCompanyId) {
            this.currentSelectedCompanyId = id;
            this.updateUrl();
          } else {
            this.currentSelectedCompanyId = id;
            this.filterComponent?.resetFilters();
          }
        })
      )
      .subscribe();
  }

  destroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  receiveFilterSelection(selection: any) {
    if (selection) {
      for (const filter in selection) {
        const val = selection[filter];
        if (filter === ASSET_FILTER_KEY_NAME) {
          const assetFilterSelection = val as AssetFilterSelectionModel;
          // When all filters are selected assetIds and divisionIds are empty, so no need to check for isAllSelected.
          this.queryParams['asset_id'] = assetFilterSelection.selected.assetIds;
          this.queryParams['division_id'] =
            assetFilterSelection.selected.divisionIds;
        } else if (filter === ASSET_PROPERTIES_FILTER_KEY_NAME && val) {
          this.setAssetPropertiesFilter(val);
        } else if (filter === DRIVER_FILTER_KEY_NAME) {
          const driverFilter = val as DriverFilterSelectionModel;
          this.queryParams['driver_id'] = driverFilter.selected.driverIds;
          this.queryParams['driver_division_id'] =
            driverFilter.selected.divisionIds &&
            driverFilter.selected.divisionIds.length > 0
              ? driverFilter.selected.divisionIds
              : null;
        } else if (filter === MULTI_ZONE_FILTER_KEY_NAME) {
          const value = val as ZoneFilterSelectionModel;
          this.queryParams['zone_id'] = value.selected.zoneIds;
          this.queryParams['category_id'] =
            value.selected.categoryIds && value.selected.categoryIds.length > 0
              ? value.selected.categoryIds
              : null;
        } else if (typeof val === 'object') {
          this.queryParams[filter] = val.isAllSelected ? [] : val.selected;
        } else {
          this.queryParams[filter] = val;
        }
      }
      // this to make the URL match with UI.
      this.queryParams.page = 1;
      this.queryParams.per_page = this.queryParams.per_page || 10;

      this.updateUrl();
    }
  }

  setAssetPropertiesFilter(filterVal: Record<string, any>) {
    const assetProperties =
      filterVal instanceof Map ? [...filterVal.keys()] : Object.keys(filterVal);
    assetProperties.forEach((k) => {
      const val = filterVal instanceof Map ? filterVal.get(k) : filterVal[k];
      if (val) {
        if (k === 'asset_custom_properties') {
          this.queryParams[k] = JSON.stringify(val);
        } else {
          this.queryParams[k] = val;
        }
      }
    });
    // Reset logic
    if (!assetProperties.length) {
      Object.keys(this.queryParams).forEach((q) => {
        if (q !== 'asset_id' && q.includes('asset')) {
          this.queryParams[q] = [];
        }
      });
    }
  }

  receivePaginationSelection(params: any) {
    // Don't need to handle empty params
    if (!params || Object.keys(params).length === 0) {
      return;
    }

    const sortParam = params?.sort as string;

    if (sortParam) {
      delete params.sort;
      const sorts = sortParam.split(':');
      const sort_by = sorts[0];
      const sort_order = sorts[1];
      params = { ...params, sort_by, sort_order };
    }
    this.queryParams = { ...this.queryParams, ...params };
    this.updateUrl();
  }

  updateUrl(): void {
    if (this.checkForRequiredParams()) {
      return;
    }
    this.queryParams.company_id = this.currentSelectedCompanyId;
    this.r.navigate([], {
      queryParams: this.queryParams,
      queryParamsHandling: 'merge'
    });
  }

  receiveAssetSelection(assetId: string) {
    this.queryParams = {
      ...this.queryParams,
      division_id: [],
      asset_id: [assetId],
      page: 1
    };
    this.filterList = [...this.filterList];
    this.updateUrl();
  }

  receiveDriverSelection(driverProfileId: string) {
    this.queryParams = {
      ...this.queryParams,
      driver_division_id: [],
      driver_id: [driverProfileId],
      page: 1
    };
    this.filterList = [...this.filterList];
    this.updateUrl();
  }

  receiveZoneSelection(zoneId: string) {
    this.queryParams = {
      ...this.queryParams,
      category_id: [],
      zone_id: [zoneId],
      page: 1
    };
    this.filterList = [...this.filterList];
    this.updateUrl();
  }

  // This method can be override in child components.
  // For example if any component/container that doens't require start and end,
  // that container can override it to only check companyId
  checkForRequiredParams() {
    return (
      !this.currentSelectedCompanyId ||
      !this.queryParams?.start_time ||
      !this.queryParams?.end_time
    );
  }
}
