<mat-card class="card-container" [ngClass]="class">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
    <mat-card-title *ngIf="this.title">
      <div class="card-title">
        <ng-container>
          {{ translateService.get( this.title ) | async | translate}}
          <ng-content select="[subtitle]"></ng-content>
        </ng-container>
        <br *ngIf="device.mediaObserver.isActive('lt-md')" />
        <span
          [ngClass]="device.mediaObserver.isActive('lt-md') ? '' : 'desktop-mode'"
          class="date-range"
          *ngIf="this.dataRange">
          {{dataRange}}
        </span>
      </div>
    </mat-card-title>

    <div class="mat-card-action">
      <ng-content select="[action]"></ng-content>
    </div>
  </mat-card-header>

  <mat-card-content class="card-content">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
