import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@src/environments/environment';
import { DriverInfoParams } from './driver-info.model';
import { Observable, of, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ZoneApiService } from '../api-service/zone-api.service';

@Injectable({
  providedIn: 'root'
})
export class DriverInfoService implements OnDestroy {
  private driverInfoMap = new Map<string, string>();
  protected unsubscribe = new Subject();

  url = `${environment.coreEntityApiBase.url}/core/v1/driverassets`;
  constructor(private zoneApiService: ZoneApiService) {}

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getDriverInfo(inputParams: DriverInfoParams): Observable<any> {
    const key = [inputParams.driverProfileId, inputParams.startTimestamp].join(
      '_'
    );
    if (this.driverInfoMap.get(key)) {
      return of(this.driverInfoMap.get(key));
    } else {
      return this.zoneApiService.getDriverInfo(inputParams).pipe(
        take(1),
        map((result) => {
          return result.body?.length > 0 ? result.body[0] : null;
        }),
        tap((result: any) => {
          this.driverInfoMap.set(key, result);
        })
      );
    }
  }
}
