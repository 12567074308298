<ng-container *ngIf="device.mediaObserver.isActive('lt-md'); else desktopView">
  <ng-container [ngTemplateOutlet]="table"></ng-container>
</ng-container>
<ng-template #desktopView>
  <app-card class="zone-activity-table">
    <ng-container [ngTemplateOutlet]="table"></ng-container>
  </app-card>
</ng-template>

<ng-template #table>
  <zui-table
    #zoneActivityTable
    [title]="title"
    [autoCollapsing]="false"
    [columns]="columns$"
    [customCellPortals]="eventCountCellPortals"
    [dataSource]="dataSource"
    [hasLoader]="false"
    [contentProjectionEnabled]="false"
    [initialPageSize]="10"
    [showSkeletonOverSpinner]="true"
    [enableCsvExport]="true"
    [csvExportOptions]="csvExportOptions"
    [uniqueRowIdentifier]="uniqueRowIdentifier"
    [showFirstLastButtons]="false"
    [enableColumnPicker]="true"
    [defaultSortId]="defaultSortBy"
    [defaultSortDirection]="defaultSortOrder">
  </zui-table>
</ng-template>

<ng-template #zoneCells let-row="row" let-column="column">
  <div class="zone-info" fxLayout="row" fxLayoutAlign="start center">
    <div
      class="zone-name-link"
      *ngIf="(showZoneFilter | async) else zoneNameTmpl">
      <a color="accent" (click)="handleZoneClick(row.zoneId)"
        >{{ row.zoneName }}</a
      >
    </div>
    <div
      class="zone-name-icon material-icons info-icon"
      (click)="handleZoneInfoClick({zoneId: row.zoneId, zoneName: row.zoneName})">
      info_outline
    </div>
  </div>
  <ng-template #zoneNameTmpl>
    <div class="zone-name">{{ row.zoneName }}</div>
  </ng-template>
</ng-template>

<ng-template #assetName let-row="row" let-column="column">
  <zui-asset-name
    [companyId]="companyId"
    [asset]="{
      id: row.assetId,
      name: row.assetName
    }"
    [emitAssetNameLink]="true"
    (assetNameClick)="handleAssetClick($event)">
  </zui-asset-name>
</ng-template>

<ng-template #driverName let-row="row" let-column="column">
  <app-asset-driver-info
    [driver]="row"
    (selectedDriverEvent)="onDriverSelected($event)"
    fxLayout="row"
    fxLayoutAlign="start center"></app-asset-driver-info>
</ng-template>
