import dayjs from 'dayjs';
import daysDuration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(daysDuration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

/**
 * Template datetime format using to show on UI
 * This format includes time zone abbreviations.
 * Abbreviations tend to be based on English. Sometimes there is a valid abbreviation in another language, and it may be confusing which to use.
 * Some time zones may never actually use the abbreviation in the region where the time zone is observed.
 * See also, the list of Time Zone Abbreviations on Wikipedia (https://en.wikipedia.org/wiki/List_of_time_zone_abbreviations)
 */
export const FORMAT_DATETIME: string = 'MM/DD/YYYY HH:mm:ss z';

export const formatTimeStamp = (
  incomingTimeStamp: string | Date,
  fmt: string
) => {
  return dayjs(incomingTimeStamp).format(fmt);
};

export const isStartUTCDate = (
  dateTime: string | Date | dayjs.Dayjs
): boolean => {
  const date = dayjs.utc(dateTime);
  return (
    date.get('hour') === 0 &&
    date.get('minute') === 0 &&
    date.get('second') === 0
  );
};

export const isEndUTCDate = (
  dateTime: string | Date | dayjs.Dayjs
): boolean => {
  const date = dayjs.utc(dateTime);
  return (
    date.get('hour') === 23 &&
    date.get('minute') === 59 &&
    date.get('second') === 59
  );
};

export const offsetInUtc = (dateTime: string): string => {
  let newDateTime = dateTime;
  // gets offset in UTC
  const utcOffset = dayjs().utcOffset();
  newDateTime = dayjs.utc(newDateTime).add(utcOffset, 'minute').toISOString();
  // for end_time, if it's current date time, don't need to add by offset to prevent mismatch.
  newDateTime =
    isStartUTCDate(newDateTime) || isEndUTCDate(newDateTime)
      ? newDateTime
      : dateTime;
  return newDateTime;
};

export const isLocalDateTime = (dateString: string): boolean => {
  const myDate = new Date(dateString);
  const timePortion =
    (myDate.getTime() - myDate.getTimezoneOffset() * 60000) % 86400000;
  return timePortion === 0;
};

export const formatFromISODateTime = (
  dayTime: string,
  format = FORMAT_DATETIME
): string => {
  return formatDateTime(dayTime, format);
};

export const formatDateTime = (dayTime: string, format: string): string => {
  return dayjs(dayTime).format(format || FORMAT_DATETIME);
};

export function getLocalTimeZone(): string {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs.tz.guess();
}

export const getDefaultDateRange = (daysToSubtract: number = 7) => {
  const endTime = new Date();
  const startDate = new Date();
  // set start date at middle night to consistent with date range filter.
  startDate.setHours(0, 0, 0, 0);
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const startTime = new Date(
    startDate.getTime() - daysToSubtract * millisecondsInADay
  );
  return {
    start_time: `${startTime.toISOString()}`,
    end_time: `${endTime.toISOString()}`
  };
};
/**
 * Convert input to dayjs standard
 * @param time: time stamp as string, assume to be UTC+0 by default
 *  */
export function convertToStandardDate(time: string): string {
  return dayjs.utc().format('YYYY-MM-DD') + 'T' + time + 'Z';
}
