import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateService, TranslateModule } from '@zonar-ui/i18n';
import { Translations } from '@app/services/i18n/translations.service';
import { ResourceLoadState } from '@src/app/models/resource-load.state';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-filter-summary',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    TranslateModule,
    NgxSkeletonLoaderModule
  ],
  templateUrl: './filter-summary.component.html',
  styleUrls: ['./filter-summary.component.scss']
})
export class FilterSummaryComponent {
  @Input() loadStatus: any;
  @Input() data = [];

  public readonly resourceLoadState = ResourceLoadState;
  constructor(
    private route: ActivatedRoute,
    public translateService: TranslateService,
    public translations: Translations
  ) {}
}
