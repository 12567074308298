import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { ZonarUITableDataSource } from '@zonar-ui/table';
import { catchError } from 'rxjs/operators';
import { Params } from '@angular/router';
import { ZoneApiService } from '@src/app/services/api-service/zone-api.service';

@Injectable()
export class ZoneActivityTableDataSource implements ZonarUITableDataSource {
  private tableLoading = new BehaviorSubject<boolean>(true);
  private errorMessage = new BehaviorSubject<string>('');
  private totalResults = new BehaviorSubject<number>(0);
  private tableDataSubject$ = new BehaviorSubject<any[]>([]);
  private paginationParamsSubject = new BehaviorSubject<any>({});
  public resetPagingSubject = new BehaviorSubject<boolean>(false);
  paginationParams$ = this.paginationParamsSubject.asObservable();
  loading$ = this.tableLoading.asObservable();
  total$ = this.totalResults.asObservable();
  errorMsg$ = this.errorMessage.asObservable();
  resetPaging$ = this.resetPagingSubject.asObservable();
  data: any[] = [];
  defaultPagination = true;

  constructor(private readonly zoneApiService: ZoneApiService) {}

  loadData(params?: Params): void {
    this.paginationParamsSubject.next(params);
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.tableDataSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  fetchData(params?: Params) {
    this.tableLoading.next(true);
    this.errorMessage.next('');
    const { start_time, end_time } = params;
    this.zoneApiService
      .getZoneActivity({
        ...params,
        start_time,
        end_time
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      )
      .subscribe((response) => {
        this.tableLoading.next(false);
        if (response.status === 200) {
          this.handleDataUpdate(response);
        } else {
          this.errorMessage.next(response.error?.detail ?? 'Unknown');
          this.totalResults.next(0);
          this.tableDataSubject$.next([]);
          this.tableLoading.next(false);
        }
      });
  }

  onTableDestroy(): void {
    this.tableDataSubject$.complete();
    this.tableLoading.complete();
  }

  private handleDataUpdate(data) {
    const resultCount = +data.headers.get('Total-Count');
    this.totalResults.next(resultCount);
    this.tableDataSubject$.next(this.addIdToData(data.body));
    this.data = data.body;
  }

  private addIdToData(dataList: any[]) {
    return dataList?.map((item) => {
      return {
        ...item,
        id: `${item.assetId}${item.zoneId}${item.driverProfile?.driverProfileId}`
      };
    });
  }

  public resetPage() {
    this.resetPagingSubject.next(true);
  }
}
