import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OverviewContainerComponent } from './containers/overview-container/overview-container.component';

const routes: Routes = [
  // TODO: add desired component below to be default route
  // {
  //   path: '<update your default route here>',
  //   component: AppComponent,
  //   canActivate: [AuthGuard],
  // },
  // Below routes are for sample report, you can change it your desired routes.
  {
    path: 'overview',
    component: OverviewContainerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
