<div class="summary-filter">
  <div class="primary" fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngFor="let item of data" class="data-content">
      <div class="summary-title">{{ item.title | translate }}</div>
      <div
        class="summary-value"
        *ngIf="(loadStatus === resourceLoadState.LOAD_SUCCESSFUL) else loading">
        {{ item.value }}
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <ngx-skeleton-loader
    count="1"
    [theme]="{'width': '85%', 'margin-left': '2.5%' , 'border-radius':'3px'}">
  </ngx-skeleton-loader>
</ng-template>
