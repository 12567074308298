import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';
import { CardComponent } from '@src/app/components/card/card.component';
import { MobileDrawerComponent } from '@src/app/components/mobile-drawer/mobile-drawer.component';
import { DeviceDetectionService } from '@src/app/services/device-detection/device-detection.service';
import { FiltersBarService } from '@src/app/services/filters-bar/filters-bar.service';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';
import { ZuiFiltersBarComponent } from '@zonar-ui/filter';
import { BaseContainerComponent } from '../base-container-component';
import { ZoneActivityTableComponent } from '@src/app/components/zone-activity-table/zone-activity-table.component';
import { ZonarUiChartModule } from '@zonar-ui/chart';
import { ZoneChartComponent } from '../../components/zone-chart/zone-chart.component';
import { SummaryComponent } from '@src/app/components/summary/summary.component';
import { FeatureToggleService } from '@zonar-ui/feature-toggle';
import { FeatureKeys } from '@src/app/shared/common.const';
@Component({
  selector: 'app-zone-activity-container',
  standalone: true,
  templateUrl: './zone-activity-container.component.html',
  styleUrls: ['./zone-activity-container.component.css'],
  imports: [
    CommonModule,
    FlexLayoutModule,
    CardComponent,
    ZuiFiltersBarComponent,
    MobileDrawerComponent,
    SummaryComponent,
    ZoneActivityTableComponent,
    ZonarUiChartModule,
    ZoneChartComponent
  ]
})
export class ZoneActivityContainerComponent
  extends BaseContainerComponent
  implements OnInit, OnDestroy
{
  // Need this to avoid NPE when reset filter
  @ViewChild(ZuiFiltersBarComponent) filterComponent: ZuiFiltersBarComponent;

  public isShowSummaryWidget: Boolean = false;
  public isShowVisualChart: Boolean = false;
  public isShowZoneFilter = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public device: DeviceDetectionService,
    private selectedCompanyService: SelectedCompanyService,
    private filterBarService: FiltersBarService,
    private features: FeatureToggleService
  ) {
    super(router, route, selectedCompanyService);
    this.features
      .valueForKey(FeatureKeys.SUMMARY_WIDGET_KEY)
      .subscribe((isShow) => (this.isShowSummaryWidget = Boolean(isShow)));
    this.features
      .valueForKey(FeatureKeys.VISUAL_CHART_KEY)
      .subscribe((isShow) => (this.isShowVisualChart = Boolean(isShow)));
    this.features
      .valueForKey(FeatureKeys.ZONE_FILTER_KEY)
      .subscribe((isShow) => {
        this.filterList = this.filterBarService.getFilterList(Boolean(isShow));
        this.isShowZoneFilter.next(Boolean(isShow));
      });
  }

  ngOnInit(): void {
    this.initComponent();
  }
  ngOnDestroy(): void {
    this.destroy();
  }
}
