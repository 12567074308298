import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { DeviceDetectionService } from '@services/device-detection/device-detection.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ResourceLoadState } from '@src/app/models/resource-load.state';
import { TranslateModule } from '@zonar-ui/i18n';

@Component({
  selector: 'app-mobile-filter-summary',
  standalone: true,
  imports: [
    CommonModule,
    MatGridListModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    TranslateModule
  ],
  templateUrl: './mobile-filter-summary.component.html',
  styleUrls: ['./mobile-filter-summary.component.scss']
})
export class MobileFilterSummaryComponent {
  @Input() data: any;
  @Input() loadStatus: any;
  public readonly resourceLoadState = ResourceLoadState;

  constructor(
    private route: ActivatedRoute,
    public device: DeviceDetectionService
  ) {}
}
