<div class="driver-name">
  <span *ngIf="!driver?.driverProfile?.fullName">
    {{ translations.overview.zoneActivityTable.notAvailableDriverInfo |
    translate }}
  </span>
  <a
    class="total-trip-text"
    color="accent"
    *ngIf="driver?.driverProfile?.fullName"
    href="javascript: void(0);"
    (click)="onDriverNameClick()"
    >{{ driver.driverProfile.fullName }}</a
  >
</div>
<div
  *ngIf="driver?.driverProfile?.fullName"
  #tooltip="matTooltip"
  class="driver-info material-icons info-icon"
  [matTooltip]="tooltipContext$ | async"
  (mouseenter)="getTooltipContext(driver)"
  matTooltipPosition="above">
  info_outline
</div>
