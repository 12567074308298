export const environment = {
  name: 'production-na',
  region: 'NA',
  production: true,
  appUrl: 'https://https://zone-visit.production.zonarsystems.net/',

  apiBase: {
    url: 'https://api.zonarsystems.net/gtcx-trip-api/v1/zone-visit'
  },

  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net'
  },

  userPreferencesApiBase: {
    url: 'https://api.zonarsystems.net/alert/v1'
  },

  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'production',

  auth: {
    clientID: 'jFK6bcF4cR0op0qaQQScOeVk2aAWYm7U',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    // These come from the Core APIs as your application is built.
    applicationId: '503ec5c9-a635-467f-978f-b5a6e8c58892',
    defaultZonarRole: '60c27143-931f-4b4b-8ac1-7aea1d978300',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_49fcea7c_73c8_4b9f_b963_84267385a267_2edbf4b'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: '<DATADOG_APPLICATION_ID>',
    clientToken: '<DATADOG_CLIENT_TOKEN>',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'prod'
  },

  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'fr-FR', 'es-ES', 'it-IT'],
    defaultLanguage: 'en-US'
  },
  here: {
    apiKeyV3: 'uJUMeJ7BI2NDrHfz9zv_1PwByr3dhqs-iIyN2pydU80'
  }
};
