import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import {
  ZonarUITableModel,
  ZonarUITableCellType as cellType
} from '@zonar-ui/table';
import { Translations } from '@src/app/services/i18n/translations.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  convertDuration,
  formatFromISODateTime
} from '@src/app/shared/utilities/utilities';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class ColumnDefinitions {
  private _translated;
  private _onDestroy$ = new Subject<void>();
  private _zoneColumns: ZonarUITableModel[];

  constructor(
    private decimalPipe: DecimalPipe,
    public translateService: TranslateService,
    public translations: Translations
  ) {
    this.translateService
      .get([
        this.translations.overview.zoneActivityTable.headings.zoneName,
        this.translations.overview.zoneActivityTable.headings.assetName,
        this.translations.overview.zoneActivityTable.headings.driverName,
        this.translations.overview.zoneActivityTable.headings.zoneEntry,
        this.translations.overview.zoneActivityTable.headings.exitZone,
        this.translations.overview.zoneActivityTable.headings.duration,
        this.translations.overview.zoneActivityTable.headings.engineHour
      ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((_translated) => (this._translated = _translated));

    this._zoneColumns = [
      {
        columnDef: 'zoneName',
        header:
          this._translated[
            this.translations.overview.zoneActivityTable.headings.zoneName
          ],
        sortable: true,
        type: cellType.Portal,
        iLink: (row: any) => `/overview`,
        cell: (row: any) => row.zoneName
      },
      {
        columnDef: 'assetName',
        header:
          this._translated[
            this.translations.overview.zoneActivityTable.headings.assetName
          ],
        sortable: true,
        type: cellType.Portal,
        cell: (row: any) => row.assetName
      },
      {
        columnDef: 'driverName',
        header:
          this._translated[
            this.translations.overview.zoneActivityTable.headings.driverName
          ],
        sortable: true,
        type: cellType.Portal,
        cell: (row: any) => row.driverName
      },
      {
        columnDef: 'zoneEntry',
        header:
          this._translated[
            this.translations.overview.zoneActivityTable.headings.zoneEntry
          ],
        sortable: true,
        type: cellType.Text,
        cell: (row: any) => formatFromISODateTime(row.zoneEntry)
      },
      {
        columnDef: 'exitZone',
        header:
          this._translated[
            this.translations.overview.zoneActivityTable.headings.exitZone
          ],
        sortable: true,
        type: cellType.Text,
        cell: (row: any) => formatFromISODateTime(row.zoneExit)
      },
      {
        columnDef: 'duration',
        header:
          this._translated[
            this.translations.overview.zoneActivityTable.headings.duration
          ],
        sortable: true,
        type: cellType.Text,
        cell: (row: any) =>
          convertDuration(Number(row.duration) / 3600, this.decimalPipe)
      },
      {
        columnDef: 'engineHour',
        header:
          this._translated[
            this.translations.overview.zoneActivityTable.headings.engineHour
          ],
        sortable: true,
        type: cellType.Text,
        cell: (row: any) =>
          convertDuration(Number(row.engineHour) / 3600, this.decimalPipe)
      }
    ];
  }

  public getColumnDefinitions() {
    return this._zoneColumns;
  }

  public get sortableColumns(): string[] {
    return this._zoneColumns
      .filter((col) => col.sortable)
      .map((col) => col.columnDef);
  }
}
