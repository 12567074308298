export enum DriverInfoSource {
  PRE_TRIP = 'PRE-TRIP-INSPECTION',
  EVIR_MOBILE = 'EVIR-MOBILE-INSPECTION',
  OPERATOR_ID = 'OPERATOR_ID',
  TABLET = 'TABLET_LOGIN',
  EXTERNAL = 'EXTERNAL',
  NOT_AVAIL = 'NOT_AVAIL'
}

export enum DriverLabel {
  PRE_TRIP = 'preTrip',
  EVIR_MOBILE = 'evirMobile',
  OPERATOR_ID = 'operator',
  TABLET = 'tablet',
  EXTERNAL = 'external',
  NOT_AVAIL = 'notAvail'
}

export enum FeatureKeys {
  SUMMARY_WIDGET_KEY = 'summary-widget',
  VISUAL_CHART_KEY = 'visual-chart',
  ZONE_FILTER_KEY = 'zone-filter'
}

export enum Entry {
  ARRIVAL = 'Arrival',
  EXIT = 'Exit'
}
